<template>
  <div class="row">
    <div class="col-6">
      <!-- cca_nome -->
      <div class="mb-2">
        <label for="nome">Nome</label>
        <b-form-input
          id="nome"
          v-model="nome"
          placeholder="Nome"
          @change="mudarNome"
          style="width: 100%"
        ></b-form-input>
      </div>
      <div class="d-flex justify-content-between mt-5">
        <div class="d-flex flex-column w-100 mr-3">
          <label>Licenciada</label>
          <b-form-group>
            <pv-dropdown
              class="w-100"
              name="licenciada"
              :options="licenciadaOpcoes"
              :filter="true"
              optionLabel="text"
              optionValue="value"
              placeholder="Nenhum selecionado"
              @change="pegarLicenciadasDoBanco"
              v-model="sistemaLicenciadaIdVmodel"
            ></pv-dropdown>
          </b-form-group>
        </div>
        <div class="d-flex flex-column w-100">
          <label>Tipo</label>
          <b-form-group>
            <pv-dropdown
              class="w-100"
              name="tipo"
              :options="modeloTiposOpcoes"
              :filter="true"
              optionLabel="text"
              optionValue="value"
              placeholder="Nenhum selecionado"
              @change="modeloPossiveis"
              v-model="modeloTiposVmodel"
            ></pv-dropdown>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex mt-2">
        <label class="mb-2 text-title">Modelo</label>
      </div>
      <b-form-group class="w-100">
        <pv-dropdown
          class="form-control w-100"
          optionLabel="text"
          optionValue="value"
          emptyFilterMessage="Nenhum resultado"
          :filter="true"
          :options="modeloPossiveisOpcoes"
          placeholder="Nenhum selecionado"
          @change="selecionarModelo(modeloVmodel)"
          v-model="modeloVmodel"
        ></pv-dropdown>
      </b-form-group>
    </div>
    <div class="col-6 d-flex w-100">
      <div class="w-50">
        <span>Variáveis obrigatórias</span>

        <div class="mt-2 overflow-auto box-variaveis">
          <label
            class="variaveis-obrigatorias"
            v-for="item in variaveisObg"
            :key="item"
          >
            {{ item }}
          </label>
        </div>
      </div>
      <div class="w-50" style="margin-left: 20px">
        <span>Variáveis</span>

        <div class="mt-2 overflow-auto box-variaveis">
          <label class="variaveis" v-for="item in variaveis" :key="item">
            {{ item }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "licenciadaOpcoes",
    "modeloTiposOpcoes",
    "modeloPossiveisOpcoes",
    "sistemaLicenciadaId",
    "modeloTipos",
    "modelo",
    "nome",
    "variaveisObg",
    "variaveis"
  ],
  data() {
    return {
      sistemaLicenciadaIdVmodel: null,
      modeloTiposVmodel: null,
      modeloVmodel: null,
      alreadyMounted: false
    };
  },
  methods: {
    pegarLicenciadasDoBanco() {
      this.$emit("pegarLicenciadasDoBanco", this.sistemaLicenciadaIdVmodel);
    },
    modeloPossiveis() {
      this.$emit("modeloPossiveis", this.modeloTiposVmodel);
    },
    selecionarModelo() {
      this.$emit("selecionarModelo", this.modeloVmodel);
    },
    mudarNome() {
      this.$emit("mudarNome", this.nome);
    }
  },
  mounted() {
    this.sistemaLicenciadaIdVmodel = this.sistemaLicenciadaId;
    this.modeloTiposVmodel = this.modeloTipos;
    this.modeloVmodel = this.modelo;
  },
  watch: {
    sistemaLicenciadaId: {
      handler(val) {
        this.sistemaLicenciadaIdVmodel = val;
      },
      deep: true,
      imediate: true
    },
    modeloTipos: {
      handler(val) {
        this.modeloTiposVmodel = val;
      },
      deep: true,
      imediate: true
    },
    modelo: {
      handler(val) {
        this.modeloVmodel = val;
      },
      deep: true,
      imediate: true
    },
    nome: {
      handler(val) {
        this.nome = val;
      },
      deep: true,
      imediate: true
    },
    variaveisObg: {
      handler(val) {
        this.variaveisObg = val;
      },
      deep: true,
      imediate: true
    },
    variaveis: {
      handler(val) {
        this.variaveis = val;
      },
      deep: true,
      imediate: true
    }
  }
};
</script>

<style>
.variaveis-obrigatorias {
  background-color: #ff7f00;
  color: white;
  padding: 2px 8px;
  margin: 1px;
  border-radius: 10px;
  word-break: break-all;
  font-size: calc(9px + 0.18vw);
}
.variaveis {
  background-color: #bdbec7;
  padding: 2px 8px;
  margin: 1px;
  border-radius: 10px;
  word-break: break-all;
  font-size: calc(9px + 0.18vw);
}
.box-variaveis {
  height: 210px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  border: 1px solid #bdbec7;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}
</style>
